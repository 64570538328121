<template>
	<div class="app-container alarm-list-comp">
		<div class="top-button-line">
			<div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show" :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        ">
				<i class="iconfont icon-loudoutu"></i> 筛选
			</div>
		</div>
		<div class="table-container">
			<finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" >
				<template v-slot:orderCode="row">
	          		<span v-if="row.datas.orderList">
	          			<span v-if="row.datas.orderList.length<1"> - </span>
	          			
	          			<el-button v-else type='text' @click.stop='toSeachOrder(row.datas)'>是</el-button>
	          			
	            		<!--<el-button type='text' v-for='(it,dex) in row.datas.orderList' :key="dex" @click.stop='toOrderDetail(it)'>
	            			{{it.orderCode}};
	            		</el-button>-->
	          		</span>
	          		<span v-else> - </span>
	        	</template>
			</finaltable>
			
		</div>
	</div>
</template>

<script>
	import finalTable from "@/components/FinalTable";
	import { deepClone } from "@/utils/utils.js";
	import { findAlarmPage } from "@/api/ruge/vlink/alarm/alarm";
	import handsRlink from "@/mixins/handsRlink.js";
	import moment from "moment";
	export default {
		name: "alermListComponent",
		mixins: [handsRlink],
		components: {
			finalTable,
		},
		data() {
			return {
				rugeRouter: require("@/routers/ruge"),
				loadingFlag: false,
				dataset: {
					paginationConfig: {
						need: true,
					},
					filterLine: {
						show: true,
					},
					header: [{
							prop: "alarmUUID",
							label: "告警ID",
							width: "280px"
						},
						{
							prop: "rworkDeviceName",
							label: "设备名称",
							width: ""
						},
						{
							prop: "alarmStatus",
							label: "告警状态",
							width: ""
						},
						{
							prop: "propertyDescription",
							label: "设备参数",
							width: ""
						},
						{
							prop: "alarmValue",
							label: "告警值",
							width: ""
						},
						{
							prop: "alarmLevel",
							label: "告警级别",
							width: ""
						},
						{
							prop: "alarmType",
							label: "告警类型",
							width: ""
						},
						{
							prop: "alarmTimestampStr",
							label: "告警时间",
							width: ""
						},
						{
							prop: "orderCode",
							label: "是否生成工单",
							width: "200"
						},
						{ prop: "operation", label: "操作", width: "100" },
					],
					tableData: [],
					// 搜索行配置
					searchLineConfig: {
						alarmUUID: {
							type: "input",
							label: "告警ID",
							value: "",
							actionType: "goSearch",
							placeholder: "请输入告警ID",
							prefixIcon: "el-icon-search",
						},
//						rworkDeviceName: {
//							type: "input",
//							label: "设备名称",
//							value: "",
//							actionType: "goSearch",
//							placeholder: "请输入设备名称",
//							prefixIcon: "el-icon-search",
//						},
						propertyDescription:{
							type: "input",
							label: "设备名称",
							value: "",
							actionType: "goSearch",
							placeholder: "请输入设备参数",
							prefixIcon: "el-icon-search",
						},
						alarmLevel: {
							type: "selector",
							label: "告警级别",
							value: "",
							placeholder: "请选择告警级别",
							actionType: "goSearch",
							optionList: [{
									label: "提示",
									value: "info",
								},
								{
									label: "一般",
									value: "warn",
								},
								{
									label: "严重",
									value: "error",
								},
							],
						},
						alarmType: {
							type: "selector",
							label: "告警类型",
							value: "",
							placeholder: "请选择告警类型",
							actionType: "goSearch",
							optionList: [{
									label: "运行",
									value: "run",
								},
								{
									label: "故障",
									value: "fault",
								},
							],
						},
						alarmContent: {
							type: "input",
							label: "告警内容",
							value: "",
							actionType: "goSearch",
							placeholder: "请输入告警内容",
							prefixIcon: "el-icon-search",
						},
						alarmValue: {
							type: "input",
							label: "告警值",
							value: "",
							actionType: "goSearch",
							placeholder: "请输入告警值",
							prefixIcon: "el-icon-search",
						},
						alarmStatus: {
				            type: "selector",
				            label: "告警状态",
				            value: "",
				            placeholder: "请选择告警状态",
				            actionType: "goSearch",
				            optionList: [
				              {
				                label: "告警",
				                value: "alarm",
				              },
				              {
				                label: "恢复",
				                value: "normal",
				              },
				            ],
				          },
				          operation: {
				            type: "button",
				            filterCount: "",
				            actionType: "clickEvent",
				            eventName: "showMoreFilters",
				            label: "更多筛选",
				          },
					},
					// 表格内容配置
					detailConfig: {
						alarmUUID: {
							clickEvent: this.tbRowDetail,
						},
						alarmTimestamp: {
							type: "dateFormat",
						},
						alarmLevel: {
							type: "enumerationColumn",
							emuList: {
								info: "提示",
								warn: "一般",
								error: "严重",
							},
						},
						alarmType: {
							type: "enumerationColumn",
							emuList: {
								run: "运行",
								fault: "故障",
							},
						},
						alarmStatus: {
							type: "tags",
							alarm: {
								type: "danger",
								label: "告警",
							},
							normal: {
								type: "success",
								label: "恢复",
							},
						},
						orderCode: {
				            type: "slot",
				            slotName: "orderCode",
			          	},
//						orderCode: {
//							clickEvent: this.toOrderDetail,
//						},
					},
					// 高级搜索配置
					advanceFilterConfig: {
						
						timestamp: {
							type: "dateRange",
							label: "告警时间",
							// value: [new Date(moment().subtract(7, "days")).getTime(), null],
							value: [null, null],
						},
						
					},
					pageVO: {
						current: 1,
						rowCount: 10,
						total: 0,
					},
				},
				alarm: {
					title: null,
					total: 0,
					list: null,
					listQuery: {
						current: 1,
						rowCount: 10,
						alarmKey: null,
						rworkDeviceName: null,
						alarmType: null,
						alarmLevel: null,
						alarmStatus: null,
						// startTime: new Date(moment().subtract(3, "days")).getTime(),
						startTime: null,
						endTime: null,
					},
				},
			};
		},
		watch: {
			loadingFlag: {
				handler(val) {
					this.$refs.finalTableRef.loadingToggle(val);
				},
			},
		},
		created() {
			// 如果从产品返回，则需要回显上次的查询条件
			if(this.$route.query.goBackFlag) {
				const curSearch = sessionStorage.getItem("alarm_list_query_params");
				if(curSearch) {
					const tempObj = JSON.parse(curSearch);
					this.alarm.listQuery = { ...this.alarm.listQuery,
						...tempObj
					};
					Object.keys(this.alarm.listQuery).forEach((item) => {
						if(this.dataset.searchLineConfig[item]) {
							this.dataset.searchLineConfig[item].value =
								this.alarm.listQuery[item];
						}
					});
					// 处理高级查询中的创建时间
					let dataTemp = [tempObj.startTime || null, tempObj.endTime || null];
					this.dataset.advanceFilterConfig.timestamp.value = dataTemp;
					this.dataset.pageVO.current = tempObj.current;
					this.dataset.pageVO.rowCount = tempObj.rowCount;
				}
			}
			this.findAlarmPage();
		},
		methods: {
			tbRowDetail(row) {
				console.log(row, '4555555555')
				let params = {
					path: '/iot/alarmRecords/detail',
					query: {
						rowId: row.alarmUUID,
					}
				}
				this.$router.push(params)
			},
			toSeachOrder(row){
				console.log('row',row);
				
				let params = {
					path: '/tenant/order/orderQuery/order/all',
					query: {
						alarmId: row.alarmUUID,
					}
				}
				this.$router.push(params)
			},
			toOrderDetail(row) {
				const routeData = this.$router.resolve({
	              	path: '/iot/orderQuery/detail',
					query: {
						orderId: row.orderId,
					}
	            })
	            window.open(routeData.href, '_blank')
			},
			findAlarmPage() {
				this.alarm.listQuery.current = 1;
				this.dataset.pageVO.current = 1;
				this.getAlarmPage();
			},
			async getAlarmPage() {
				let param = this.alarm.listQuery;
				sessionStorage.setItem("alarm_list_query_params", JSON.stringify(param));
				for(let pn in param) {
					!param[pn] && param[pn] !== 0 && (param[pn] = null);
				}
				this.loadingFlag = true;
				let reqData={};
				for(let keys in param){
					if(param[keys]) reqData[keys]=param[keys];
				}
				let response = await this.initAlarmlist(reqData);
				if(response){
					console.log(33333333,response)
					this.dataset.pageVO.total = response.total;
					response.rows.map((it, dex) => it.alarmCode = dex)
					this.dataset.tableData = response.rows;
				}
				this.loadingFlag = false;
				
//				findAlarmPage(param).then((response) => {
//						this.dataset.pageVO.total = response.total;
//						response.rows.map((it, dex) => it.alarmCode = dex)
//						this.dataset.tableData = response.rows;
//						this.loadingFlag = false;
//					})
//					.catch((err) => {
//						console.log("error: " + err);
//						this.loadingFlag = false;
//					});
			},
			tableEventHandler(datas) {
				console.log("xxx1222", datas);
				if(datas.type === "goSearch") {
					if(datas.params.timestamp && datas.params.timestamp.length > 0) {
						this.alarm.listQuery.startTime = datas.params.timestamp[0] ?
							new Date(
								JSON.parse(JSON.stringify(datas.params.timestamp[0]))
							).getTime() :
							"";
						this.alarm.listQuery.endTime = datas.params.timestamp[1] ?
							new Date(
								JSON.parse(JSON.stringify(datas.params.timestamp[1]))
							).getTime() :
							"";
					}
					this.alarm.listQuery = { ...this.alarm.listQuery,
						...datas.params
					};
					delete this.alarm.listQuery.timestamp;
					this.findAlarmPage();
				} else if(datas.type === "paginationChange") {
					this.alarm.listQuery.current = datas.params.current.page;
					this.alarm.listQuery.rowCount = datas.params.current.limit;
					this.getAlarmPage();
				} else if(datas.type === "updateSelectionList") {
					this.selectionList = datas.list;
				} else if(datas.type === "iconClick") {
					switch(datas.eventName) {
						case "view":
							this.$router.push({
								path: "/iot/alarm/detail",
								query: {
									alarmId: datas.row.alarmUUID,
								},
							});
							break;
					}
				}
			},
		},
	};
</script>

<style scoped lang="less">
	.alarm-list-comp {
		.top-button-line {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			.header-filter-button {
				cursor: pointer;
				width: 84px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				border-radius: 3px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
			}
			.filter-button-open {
				color: #409eff;
				background: #ecf5ff;
				border: 1px solid #c6e2ff;
			}
			.filter-button-close {
				color: #2a4158;
				background: #ffffff;
				border: 1px solid #e7e8eb;
			}
		}
		.table-container {
			margin-top: 25px;
		}
	}
</style>